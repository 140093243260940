import { gql } from "@apollo/client";

export const GET_USERS = gql`
  query {
    accounts {
      accounts {
        id
        username
        roles
        kycVerified
        twoFactorAuthEnabled
      }
    }
  }
`;
