import { gql } from '@apollo/client';

export const ADD_ROLE_ARBITRATION = gql`
  mutation addRoleArbitration($id: String!) {
    accounts {
      addRoleArbitration(id: $id) {
        success
        message
      }
    }
  }
`;

export const REMOVE_ROLE_ARBITRATION = gql`
  mutation removeRoleAbitration($id: String!) {
    accounts {
      removeRoleAbitration(id: $id) {
        success
        message
      }
    }
  }
`;

export const ADD_ROLE_ADMIN = gql`
  mutation addRoleAdmin($id: String!) {
    accounts {
      addRoleAdmin(id: $id) {
        success
        message
      }
    }
  }
`;

export const REMOVE_ROLE_ADMIN = gql`
  mutation removeRoleAdmin($id: String!) {
    accounts {
      removeRoleAdmin(id: $id) {
        success
        message
      }
    }
  }
`;
