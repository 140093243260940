import {
  Box,
  Text,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Menu,
  MenuGroup,
  Button,
  Flex,
  Image,
} from "@chakra-ui/react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useState } from "react";

const AppLayout = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const { authState, logout } = useAuth();
  const handleLogout = async () => {
    setLoading(true);
    await logout();
    setLoading(false);
  };
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        minHeight: "100vh",
      }}
      position={"relative"}
    >
      <Box p={"12px"} display={"flex"} justifyContent={"flex-end"}>
        <Menu>
          <MenuButton as={Button} colorScheme="pink">
            <Flex
              justifyContent={"space-between"}
              alignItems={"center"}
              minW={"72px"}
              gap={"12px"}
            >
              {authState?.isAuthenticated &&
                authState?.user?.avatars?.length > 0 && (
                  <Image
                    w={"32px"}
                    borderRadius={"50%"}
                    src={
                      authState?.user?.avatars && authState?.user?.avatars[0]
                    }
                  ></Image>
                )}
              <Text>
                {authState?.isAuthenticated
                  ? authState?.user?.username
                  : "Profile"}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuGroup title="Profile">
              <MenuItem>My Account</MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Help">
              {!authState?.isAuthenticated && (
                <MenuItem>
                  <NavLink style={{ width: "100%" }} to={"/signin"}>
                    Sign In
                  </NavLink>
                </MenuItem>
              )}
              {!authState?.isAuthenticated && (
                <MenuItem>
                  <NavLink style={{ width: "100%" }} to={"/signup"}>
                    Sign Up
                  </NavLink>
                </MenuItem>
              )}
              {authState?.isAuthenticated && (
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              )}
              <MenuItem>
                <Text>
                  Roles:
                  {authState?.isAuthenticated &&
                    authState?.user?.roles &&
                    authState?.user?.roles?.join(", ")}
                </Text>
              </MenuItem>
              <MenuItem>
                <a
                  href="/room"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Text>Room</Text>
                </a>
              </MenuItem>
              <MenuItem onClick={() => navigate("/createGame")}>
                <Text>Game</Text>
              </MenuItem>
              <MenuItem onClick={() => navigate("/enable2fa")}>
                <Text>2FA</Text>
              </MenuItem>
            </MenuGroup>
          </MenuList>
        </Menu>
      </Box>
      <Box
        width={{ sm: "100%" }}
        mx="auto"
        sx={{ paddingTop: "20px" }}
        overflow="auto"
      >
        {children}
      </Box>
    </Box>
  );
};

const DefaultLayout = ({ children }) => {
  return <AppLayout>{children}</AppLayout>;
};

export default DefaultLayout;
