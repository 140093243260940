import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useWebSocket from "react-use-websocket";
import { useAuth } from "../../../contexts/AuthContext";
import {
  Box,
  Button,
  Flex,
  Input,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import Room from "..";

const WSS_BASE_URL =
  process.env.REACT_APP_WSS_BASE_URL || "ws://localhost:3010";

const RoomControl = () => {
  const [roomId, setRoomId] = useState("");
  const [joinedRooms, setJoinedRooms] = useState([]);
  const navigate = useNavigate();
  const { authState } = useAuth();
  const isArbiter = authState?.user?.roles?.includes("arbitration");
  const { sendJsonMessage, lastMessage } = useWebSocket(`${WSS_BASE_URL}`, {
    onOpen: () => console.log("Connected to WebSocket"),
    onClose: () => console.log("Disconnected from WebSocket"),
    shouldReconnect: (closeEvent) => true,
  });

  const handleCreateRoom = () => {
    sendJsonMessage({ type: "CREATE_ROOM", userId: authState?.user?._id });
  };

  const handleJoinRoom = () => {
    sendJsonMessage({
      type: "JOIN_ROOM",
      roomId,
      userId: authState?.user?._id,
    });
  };

  const handleLeaveRoom = (roomId) => {
    sendJsonMessage({
      type: "LEAVE_ROOM",
      roomId,
      userId: authState?.user?._id,
    });
    setJoinedRooms((prevRooms) => prevRooms.filter((id) => id !== roomId));
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      if (data.type === "ROOM_CREATED" || data.type === "ROOM_JOINED") {
        setJoinedRooms((prevRooms) => [...prevRooms, data.roomId]);
      } else if (data.type === "ERROR") {
        alert(data.message);
      }
    }
  }, [lastMessage]);

  return (
    <Flex direction={"column"} gap={"24px"} mx={"auto"}>
      <Flex direction={"column"} gap={"24px"} maxW={"360px"} mx={"auto"}>
        <Button onClick={handleCreateRoom}>Create Room</Button>
        <Flex>
          <Input
            type="text"
            placeholder="Enter Room ID"
            value={roomId}
            onChange={(e) => setRoomId(e.target.value)}
          />
          <Button onClick={handleJoinRoom}>Join Room</Button>
        </Flex>
      </Flex>
      <Box mt={4} mb={4} w={"100%"}>
        <Text fontWeight={"bold"} textAlign={"center"}>
          Joined Rooms:
        </Text>
        <List>
          {joinedRooms.map((roomId, index) => (
            <ListItem key={index}>
              <Room roomId={roomId} />
              <Button ml={4} onClick={() => handleLeaveRoom(roomId)}>
                Leave Room
              </Button>
            </ListItem>
          ))}
        </List>
      </Box>
    </Flex>
  );
};

export default RoomControl;
