import { Box, Text, Button, Flex } from "@chakra-ui/react";
import { useAuth } from "../../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

const Step2Page = ({ step, setStep, handleBack, handleNextStep }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    resendVerificationEmail,
    refreshToken,
    checkVerifyEmail,
    setAuthState,
    authState: { user },
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleResend = async () => {
    setLoading(true);
    const data = {
      userId: user?._id || "",
      email: user?.email || "",
    };
    await resendVerificationEmail(data);
    setLoading(false);
  };

  const CheckVerificationEmail = async () => {
    try {
      await refreshToken();
      const isVerified = await checkVerifyEmail(user?._id);
      const updatedUser = { ...user, isVerified: isVerified };
      setAuthState((prevState) => ({
        ...prevState,
        user: updatedUser,
        isAuthenticated: true,
        isLoading: false,
      }));
    } catch (error) {
      console.error("Error during email verification check:", error);
    }
  };

  useEffect(() => {
    CheckVerificationEmail();
  }, []);

  return (
    <>
      <Box h="100vh" w="100%">
        <Box
          w={"500px"}
          minH={"600px"}
          margin={"auto"}
          p={"24px"}
          backgroundColor={"gainsboro"}
        >
          <Box
            className="nav-step"
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box minW={"120px"}></Box>
            <Button minW={"120px"}>
              <NavLink to={"/signin"}>Login</NavLink>
            </Button>
          </Box>
          <Box>
            <Box
              w="100%"
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              h={"500px"}
            >
              <Flex h={"100%"} px={"24px"} direction={"column"} gap={"48px"}>
                <Text
                  mt="100px"
                  fontSize={{ base: "24px" }}
                  fontWeight={{ base: "500", sm: "700" }}
                  color={"black !important"}
                >
                  Email Send Message
                </Text>
                {!user?.isVerified ? (
                  <Button
                    maxW={"240px"}
                    onClick={() => handleResend()}
                    isLoading={loading}
                  >
                    Resend Email
                  </Button>
                ) : (
                  <Text fontSize={"24px"}>Verify Email Success</Text>
                )}
              </Flex>
              <Box
                className="footer-step"
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Button
                  onClick={() => {
                    navigate("/signup");
                  }}
                  minW={"120px"}
                >
                  Back
                </Button>

                {user?.isVerified && (
                  <Button
                    minW={"120px"}
                    onClick={() => {
                      navigate("/signup/step3");
                    }}
                  >
                    Continue
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Step2Page;
