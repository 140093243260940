import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignInPage from "./pages/signin";
import SignUpPage from "./pages/signup";
import DefaultLayout from "./layouts";
import HomePage from "./pages/home";
import Step1Page from "./pages/signup/steps/step1";
import Step2Page from "./pages/signup/steps/step2";
import Step3Page from "./pages/signup/steps/step3";
import NotFoundPage from "./pages/notFound";
import AdminRoute from "./components/PrivateRouter/AdminRoute";
import AdminPage from "./pages/admin";
import RoomControl from "./pages/room/roomControl";
import Room from "./pages/room";
import PrivateRoute from "./components/PrivateRouter/AdminRoute";
import Enable2FA from "./pages/2fa";
import Verify2FA from "./pages/2fa/verify2fa";
import GamePage from "./pages/game";
import GameControl from "./pages/game/createGame";

function App() {
  return (
    <BrowserRouter>
      <DefaultLayout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<SignInPage />} />
          <Route path="/signup" element={<Step1Page />} />
          <Route path="/signup/step2" element={<Step2Page />} />
          <Route path="/signup/step3" element={<Step3Page />} />
          <Route path="/admin" element={<AdminRoute />}>
            <Route path="/admin" element={<AdminPage />} />
          </Route>
          <Route path="/room" element={<PrivateRoute />}>
            <Route path="/room" element={<RoomControl />} />
          </Route>
          <Route path="/room/:roomId" element={<PrivateRoute />}>
            <Route path="/room/:roomId" element={<Room />} />
          </Route>
          <Route path="/enable2fa" element={<PrivateRoute />}>
            <Route path="/enable2fa" element={<Enable2FA />} />
          </Route>
          <Route path="/game/:gameId" element={<PrivateRoute />}>
            <Route path="/game/:gameId" element={<GamePage />} />
          </Route>
          <Route path="/createGame" element={<PrivateRoute />}>
            <Route path="/createGame" element={<GameControl />} />
          </Route>
          <Route path="/verify2fa" element={<Verify2FA />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </DefaultLayout>
    </BrowserRouter>
  );
}

export default App;
