import {
  Box,
  Text,
  Input,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Flex,
  Button,
  Image,
  Spinner,
} from "@chakra-ui/react";
import { useAuth } from "../../../contexts/AuthContext";
import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import UploadImage from "../../../components/UploadImage";

const Step3Page = ({ step, setStep, handleBack, handleNextStep }) => {
  const avatarsDefault = [
    "https://via.placeholder.com/100",
    "https://via.placeholder.com/100",
    "https://via.placeholder.com/100",
    "https://via.placeholder.com/100",
    "https://via.placeholder.com/100",
  ];
  const [formData, setFormData] = useState({
    avatars: Array(5).fill(""),
    username: "",
  });
  const {
    setProfile,
    authState: { user },
  } = useAuth();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { avatars, username } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onUploadComplete = (index, url) => {
    const newAvatars = [...avatars];
    newAvatars[index] = url;
    setFormData({ ...formData, avatars: newAvatars });
  };

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    await setProfile(formData);
    setLoading(false);
    navigate("/");
  };

  return (
    <>
      <Box h="100vh" w="100%">
        <Box
          w={"500px"}
          minH={"600px"}
          margin={"auto"}
          p={"24px"}
          backgroundColor={"gainsboro"}
        >
          <Box
            className="nav-step"
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box minW={"120px"}></Box>
            <Button minW={"120px"}>
              <NavLink to={"/signin"}>Login</NavLink>
            </Button>
          </Box>
          <Box>
            <form action="" onSubmit={onSubmit}>
              <Box
                w="100%"
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                h={"500px"}
              >
                <Flex
                  w="100%"
                  flexDirection={"column"}
                  gap={"12px"}
                  py={"12px"}
                >
                  <FormControl borderColor={"gray"}>
                    <FormLabel>Avatars</FormLabel>
                    <Flex gap="12px" justifyContent={"space-between"}>
                      {avatars.map((avatar, index) => (
                        <Box key={index}>
                          <UploadImage
                            onUploadComplete={(url) =>
                              onUploadComplete(index, url)
                            }
                            defaultAvatar={avatarsDefault[index]}
                          />
                          {/* {avatar && (
                            <Image
                              src={avatar}
                              alt={`Avatar ${index + 1}`}
                              width="80px"
                              height="80px"
                              borderRadius={"50%"}
                            />
                          )} */}
                        </Box>
                      ))}
                    </Flex>
                  </FormControl>
                  <FormControl borderColor={"gray"}>
                    <FormLabel>Username</FormLabel>
                    <Input
                      type="text"
                      name="username"
                      value={username}
                      onChange={onChange}
                      required
                    />
                  </FormControl>
                </Flex>
                <Box
                  className="footer-step"
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  <Button
                    onClick={() => {
                      navigate("/signup/step2");
                    }}
                    minW={"120px"}
                  >
                    Back
                  </Button>
                  <Button type="submit" minW={"120px"} isLoading={loading}>
                    Continue
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Step3Page;
