import {
    Box,
    Table,
    TableContainer,
    TableCaption,
    Tr,
    Th,
    Thead,
    Td,
    Tbody,
    Button,
    Flex,
    Text,
  } from "@chakra-ui/react";
  import React from "react";
  import { useQuery, useMutation } from "@apollo/client";
  import { GET_USERS } from "../../../api/graphQL/queries/userQuery";
  import {
    ADD_ROLE_ADMIN,
    REMOVE_ROLE_ADMIN,
  } from "../../../api/graphQL/mutations/userMutation";
  
  const UpgradeAdmin = () => {
    const { loading, error, data, refetch } = useQuery(GET_USERS);
    const [
        addRoleAdmin,
      { loading: mutationLoading, error: mutationError, data: mutationData },
    ] = useMutation(ADD_ROLE_ADMIN, {
      onCompleted: () => refetch(),
    });
    const [
        removeRoleAdmin,
      { loading: removeLoading, error: removeError, data: removeData },
    ] = useMutation(REMOVE_ROLE_ADMIN, {
      onCompleted: () => refetch(),
    });
  
    const handleAdd = async (id) => {
      try {
        await addRoleAdmin({ variables: { id } });
      } catch (err) {
        console.error(err);
      }
    };
  
    const handleRemove = async (id) => {
      try {
        await removeRoleAdmin({ variables: { id } });
      } catch (err) {
        console.error(err);
      }
    };
  
    if (loading)
      return (
        <Box textAlign={"center"}>
          <Text>Loading...</Text>
        </Box>
      );
    if (error)
      return (
        <Box textAlign={"center"}>
          <Text color={"red"}>Error: {error.message}</Text>
        </Box>
      );
  
    return (
      <Box w={"100%"} p={"24px"}>
        <TableContainer>
          <Table variant="simple">
            <TableCaption>
              <Text>Uprade role player to role Admin</Text>
              {mutationLoading && <Text>Updating...</Text>}
              {mutationError && <Text>Error: {mutationError.message}</Text>}
              {mutationData && (
                <Text>
                  Add: {mutationData.accounts.addRoleAdmin.success
                    ? "Success"
                    : "Failed"}
                  : {mutationData.accounts.addRoleAdmin.message}
                </Text>
              )}
              {removeLoading && <Text>Removing...</Text>}
              {removeError && <Text>Error: {removeError.message}</Text>}
              {removeData && (
                <Text>
                  Remove: {removeData.accounts.removeRoleAdmin.success
                    ? "Success"
                    : "Failed"}
                  : {removeData.accounts.removeRoleAdmin.message}
                </Text>
              )}
            </TableCaption>
            <Thead>
              <Tr>
                <Th>User Id</Th>
                <Th>Username</Th>
                <Th>Roles</Th>
                <Th>KYC verified</Th>
                <Th>2FA enabled</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data &&
                data?.accounts?.accounts?.map((item, index) => (
                  <Tr key={index}>
                    <Td>{item.id}</Td>
                    <Td>{item.username}</Td>
                    <Td>{item?.roles && item?.roles?.join(", ")}</Td>
                    <Td>{item.kycVerified ? "Enable" : "Disable"}</Td>
                    <Td>{item.twoFactorAuthEnabled ? "Enable" : "Disable"}</Td>
                    <Td>
                      <Flex gap={"12px"}>
                        <Button onClick={() => handleAdd(item.id)}>Grant</Button>
                        <Button onClick={() => handleRemove(item.id)}>
                          Revoke
                        </Button>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  export default UpgradeAdmin;
  