import { Box, Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import React, { useState } from "react";
import UpgradeArbitration from "./upgradeArbitration";
import UpgradeAdmin from "./upgradeAdmin";
const tabs = [
  {
    name: "Upgrade arbitration",
    content: <UpgradeArbitration />,
  },
  {
    name: "Upgrade Administrator",
    content: <UpgradeAdmin />,
  },
  {
    name: "Tab 3",
    content: "",
  },
  {
    name: "Tab 4",
    content: "",
  },
  {
    name: "Tab 5",
    content: "",
  },
];

const AdminPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Box w={"100%"} p={"24px"}>
      <Grid
        h="600px"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap={4}
      >
        <GridItem rowSpan={2} colSpan={1} bg="Gray">
          <Flex flexDirection={"column"} gap={"12px"} p={"12px"}>
            {tabs.map((tab, index) => (
              <Button
                key={index}
                onClick={() => {
                  setTabIndex(index);
                }}
              >
                {tab.name}
              </Button>
            ))}
          </Flex>
        </GridItem>
        <GridItem colSpan={4} rowSpan={2} border={"1px solid gray"}>{tabs[tabIndex].content}</GridItem>
      </Grid>
      <Box className="Side-bar"></Box>
    </Box>
  );
};

export default AdminPage;
