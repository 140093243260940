import {
  Box,
  Text,
  Input,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  FormControl,
  Flex,
  Button,
  Link,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
const SignInPage = ({ step, setStep, handleBack, handleNextStep }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { login, authState } = useAuth();

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const result = await login(formData);
    console.log({ result });
    setLoading(false);
    if (result.twoFactorAuthEnabled) navigate("/verify2fa");
    if (result.success) navigate("/");
  };

  return (
    <>
      <Box h="100vh" w="100%">
        <Box
          w={"500px"}
          minH={"600px"}
          margin={"auto"}
          p={"24px"}
          backgroundColor={"gainsboro"}
        >
          <Box
            className="nav-step"
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box minW={"120px"}></Box>
            <Button minW={"120px"}>
              <NavLink to={"/signup"}>Sign Up</NavLink>
            </Button>
          </Box>
          <Box>
            <form action="" onSubmit={onSubmit}>
              <Box
                w="100%"
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"space-between"}
                h={"500px"}
              >
                <Flex
                  w="100%"
                  flexDirection={"column"}
                  gap={"12px"}
                  py={"12px"}
                >
                  <FormControl borderColor={"gray"}>
                    <FormLabel>Email address</FormLabel>
                    <Input
                      name="email"
                      type="email"
                      value={email}
                      onChange={onChange}
                      required
                    />
                  </FormControl>
                  <FormControl borderColor={"gray"}>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      name="password"
                      value={password}
                      onChange={onChange}
                      required
                    />
                  </FormControl>
                  <Box>
                    <Text textAlign={"center"}>
                      ------------------OR--------------------
                    </Text>
                  </Box>
                  <Link
                    textAlign={"center"}
                    href={`${API_BASE_URL}/auth/google`}
                  >
                    <Button>Google Acc Log In</Button>
                  </Link>
                </Flex>
                <Box
                  className="footer-step"
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {step > 0 && (
                    <Button
                      onClick={() => {
                        handleBack();
                      }}
                      minW={"120px"}
                    >
                      Back
                    </Button>
                  )}
                  <Button minW={"120px"} type="submit" isLoading={loading}>
                    Continue
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignInPage;
