import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import {
  Box,
  Button,
  Flex,
  Input,
  List,
  ListItem,
  Text,
} from "@chakra-ui/react";
import io from "socket.io-client";
import axios from "axios";

const WSS_URL = process.env.REACT_APP_WSS2_BASE_URL || "http://localhost:3011";
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

const GameControl = () => {
  const [gameId, setGameId] = useState("");
  const navigate = useNavigate();

  const handleCreateGame = async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/newGame`);
      const { board } = response.data;
      navigate(`/game/${board?.game_id}`)
    } catch (error) {
      console.error("Error fetching game data:", error);
    }
  };

  const handleJoinGame = async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/loadGame?game_id=${gameId}`
      );
      const { game } = response.data;
      if (game) navigate(`/game/${gameId}`);
    } catch (error) {
      console.error("Error fetching game data:", error);
    }
  };

  return (
    <Flex direction={"column"} gap={"24px"} mx={"auto"}>
      <Flex direction={"column"} gap={"24px"} maxW={"360px"} mx={"auto"}>
        <Button onClick={handleCreateGame}>Create game</Button>
        <Flex>
          <Input
            type="text"
            placeholder="Enter Room ID"
            value={gameId}
            onChange={(e) => setGameId(e.target.value)}
          />
          <Button onClick={handleJoinGame}>Join game</Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default GameControl;
