import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import toast from "react-hot-toast";

const AuthContext = createContext();

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    token: localStorage.getItem("token") || "",
    isAuthenticated: false,
    user: JSON.parse(localStorage.getItem("user")) || null,
    isLoading: true,
  });

  console.log({ authState });

  useEffect(() => {
    const fetching = async () => {
      try {
        await refreshToken();
      } catch (error) {
        console.error("Error fetching:", error);
      }
    };
    fetching();
  }, []);

  useEffect(() => {
    const getUser = async () => {
      try {
        const response = await axios.post(`${API_BASE_URL}/profile`);
        localStorage.setItem("user", JSON.stringify(response.data));
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          user: response.data,
          isLoading: false,
        }));
      } catch (error) {
        setAuthState((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
        console.error("Error fetching user:", error);
      }
    };

    if (authState.token) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authState.token}`;
      getUser();
    } else {
      delete axios.defaults.headers.common["Authorization"];
      setAuthState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
    }
  }, [authState.token]);

  const api = axios.create({
    baseURL: `${API_BASE_URL}`,
    withCredentials: true,
  });

  const refreshToken = async () => {
    try {
      const res = await api.post("/refresh-token", {});
      localStorage.setItem("token", res.data.accessToken);
      api.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${res.data.accessToken}`;
      return res.data;
    } catch (err) {
      setAuthState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      console.error(err.response.data);
      return false;
    }
  };

  const register = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(formData);
    console.log({ formData });
    try {
      const res = await api.post("/auth/register", body, config);
      console.log({ data: res.data });
      localStorage.setItem("user", JSON.stringify(res.data.user));
      setAuthState({
        isAuthenticated: true,
        user: res.data.user,
        isLoading: false,
      });
      return { success: true };
    } catch (err) {
      toast.error(`${err.response.data}`);
      console.error(err.response.data);
      return { success: false, error: err.response.data };
    }
  };

  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(formData);
    try {
      const res = await axios.post(`${API_BASE_URL}/auth/login`, body, config);
      if (res.data.twoFactorAuthEnabled) {
        const newUser = {};
        newUser._id = res.data.userId;
        setAuthState((prevState) => ({
          ...prevState,
          user: newUser,
        }));
        return {
          twoFactorAuthEnabled: res.data.twoFactorAuthEnabled,
        };
      }
      localStorage.setItem("token", res.data.accessToken);
      setAuthState({
        token: res.data.accessToken,
        isAuthenticated: true,
        isLoading: false,
      });
      return { success: true };
    } catch (err) {
      toast.error(`${err.response.data}`);
      console.error(err.response.data);
      return { success: false, error: err.response.data };
    }
  };

  const resendVerificationEmail = async (data) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify(data);
    try {
      const res = await api.post("/auth/resend-verification", body, config);
      toast.success(`${res.data.msg}`);
    } catch (err) {
      toast.error(`${err.response.data}`);
      console.error(err.response.data);
    }
  };

  const checkVerifyEmail = async (userId) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const res = await axios.post(
        `${API_BASE_URL}/check-verify-email`,
        {
          userId,
        },
        config
      );
      return res.data?.isVerified;
    } catch (err) {
      console.error(err.response.data);
      return false;
    }
  };

  const getProfile = async () => {
    let token = "";
    if (authState.token) token = authState.token;
    else {
      const { accessToken } = await refreshToken();
      token = accessToken;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const res = await axios.post(`${API_BASE_URL}/profile`, {}, config);
      if (res.data.user) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          isLoading: false,
          user: res.data.user,
        }));
      }
      return res.data;
    } catch (err) {
      console.error(err.response.data);
      return false;
    }
  };

  const setProfile = async (formData) => {
    let token = "";
    if (authState.token) token = authState.token;
    else {
      const { accessToken } = await refreshToken();
      token = accessToken;
    }
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    console.log({ formData });
    const body = JSON.stringify(formData);
    try {
      const res = await axios.post(
        `${API_BASE_URL}/updateProfile`,
        body,
        config
      );
      if (res.data.user) {
        localStorage.setItem("user", JSON.stringify(res.data.user));
        setAuthState((prevState) => ({
          ...prevState,
          isAuthenticated: true,
          isLoading: false,
          user: res.data.user,
        }));
      }
    } catch (err) {
      console.error(err.response.data);
    }
  };

  const logout = async () => {
    try {
      const res = await api.get("/auth/logout");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      setAuthState({
        token: "",
        isAuthenticated: false,
        isLoading: false,
        user: null,
      });
      return res.data;
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        authState,
        setAuthState,
        register,
        resendVerificationEmail,
        checkVerifyEmail,
        setProfile,
        logout,
        refreshToken,
        getProfile,
        login,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
