import { Box, Text, Image } from "@chakra-ui/react";

const HomePage = () => {
  return (
    <>
      <Box h="100vh" w="100%">
        <Text
          textAlign="center"
          mt="100px"
          fontSize={{ base: "32", sm: "48px" }}
          fontWeight={{ base: "500", sm: "700" }}
          color={"black !important"}
        >
          Home
        </Text>
        <Image
          margin={"auto"}
          src={""}
        />
      </Box>
    </>
  );
};

export default HomePage;
