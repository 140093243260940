import React, { useState, useRef } from "react";
import { Box, Button, Input, Image, Spinner } from "@chakra-ui/react";
import { Buffer } from "buffer";
import { ipfsClient } from "../../api/ipfsApi";

const UploadImage = ({ onUploadComplete, defaultAvatar }) => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(defaultAvatar);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
          setFile(selectedFile);
          setPreview(URL.createObjectURL(selectedFile));
      }
  };

  const handleUpload = async () => {
      if (!file) return;

      setLoading(true);
      try {
          const reader = new FileReader();
          reader.onloadend = async () => {
              const buffer = Buffer.from(reader.result);
              const added = await ipfsClient.add(buffer);
              const url = `https://ipfs.infura.io/ipfs/${added.path}`;
              setLoading(false);
              onUploadComplete(url);
          };
          reader.readAsArrayBuffer(file);
      } catch (error) {
          console.error('Error uploading file to IPFS:', error);
          setLoading(false);
      }
  };

  const handleAvatarClick = () => {
      fileInputRef.current.click();
  };

  return (
      <Box textAlign="center">
          <Input 
              type="file" 
              onChange={handleFileChange} 
              ref={fileInputRef} 
              display="none" 
          />
          <Image 
              src={preview} 
              alt="Avatar" 
              width="80px" 
              height="80px" 
              borderRadius="full" 
              cursor="pointer" 
              onClick={handleAvatarClick}
              mb={2}
          />
          {file && !loading && (
              <Button onClick={handleUpload}>
                  Upload
              </Button>
          )}
          {loading && <Spinner />}
      </Box>
  );
};

export default UploadImage;