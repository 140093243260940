import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import toast from "react-hot-toast";
import { Button, Input, Box, Text } from "@chakra-ui/react";
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
const Verify2FA = () => {
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const {
    authState: { user },
    setAuthState,
  } = useAuth();

  const handleVerify = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/verify2FA`,
        { userId: user._id, otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.setItem("token", response.data.accessToken);
        setAuthState({
          token: response.data.accessToken,
          isAuthenticated: true,
          isLoading: false,
        });
        toast.success("2FA verified successfully");
        navigate("/");
      } else {
        setMessage("Invalid OTP");
      }
    } catch (error) {
      setMessage("Verification failed");
      console.error(error);
    }
  };

  return (
    <Box w={"600px"} mx={"auto"}>
      <Text fontSize={"36px"}>Verify 2FA</Text>
      <Input
        type="text"
        placeholder="Enter OTP"
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />
      <Button onClick={handleVerify}>Verify</Button>
      {message && <p>{message}</p>}
    </Box>
  );
};

export default Verify2FA;
