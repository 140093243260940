import React, { useState } from "react";
import axios from "axios";
import { Box, Button, Image, Input, Text } from "@chakra-ui/react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";
const Enable2FA = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [secret, setSecret] = useState("");
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState("");
  const {
    authState: { user },
  } = useAuth();

  const navigate = useNavigate();
  const enable2FA = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/enable2FA`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setQrCodeUrl(response.data.qrCodeUrl);
      setSecret(response.data.secret);
      setMessage(
        "2FA enabled successfully. Scan the QR code with your authenticator app."
      );
    } catch (error) {
      setMessage("Error enabling 2FA");
      console.error(error);
    }
  };

  const verify2FA = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/verify2FA`,
        { userId: user._id, otp },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        localStorage.setItem("token", response.data.accessToken);
        setMessage("2FA verified successfully");
        toast.success("2FA verified successfully");
        navigate("/");
      } else {
        setMessage("Invalid OTP");
      }
    } catch (error) {
      setMessage("Error verifying 2FA");
      console.error(error);
    }
  };

  const disable2FA = async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/disable2FA`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setMessage(
        "2FA disable successfully. Scan the QR code with your authenticator app."
      );
      toast.success("2FA disable successfully");
      navigate("/");
    } catch (error) {
      setMessage("Error disabling 2FA");
      console.error(error);
    }
  };

  if (user?.twoFactorAuthEnabled)
    return (
      <Box maxW={"600px"} mx={"auto"}>
        <Text fontSize={"36px"}>Disable Two-Factor Authentication</Text>
        <Button onClick={disable2FA}>Disable 2FA</Button>
        <Text>{message}</Text>
      </Box>
    );

  return (
    <Box maxW={"600px"} mx={"auto"}>
      <Text fontSize={"36px"}>Enable Two-Factor Authentication</Text>
      <Button onClick={enable2FA}>Enable 2FA</Button>
      {qrCodeUrl && (
        <Box>
          <Image src={qrCodeUrl} alt="QR Code" />
          <Input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
          <Button onClick={verify2FA}>Verify OTP</Button>
          <Text>{message}</Text>
        </Box>
      )}
    </Box>
  );
};

export default Enable2FA;
