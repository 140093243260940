import React, { useEffect, useState, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { useAuth } from "../../contexts/AuthContext";
import { Box, Button, Flex, SimpleGrid, Text } from "@chakra-ui/react";

const WSS_BASE_URL =
  process.env.REACT_APP_WSS_BASE_URL || "ws://localhost:3010";
const ICE_USER = process.env.REACT_APP_ICE_USER;
const ICE_CREDENTIAL = process.env.REACT_APP_ICE_CREDENTIAL;

const Room = ({ roomId }) => {
  const { authState } = useAuth();
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(`${WSS_BASE_URL}`, {
    onOpen: () => {
      console.log(`Connected to WebSocket for room ${roomId}`);
      sendJsonMessage({ type: "JOIN_ROOM", roomId, userId: authState?.user?._id });
    },
    onClose: () => console.log(`Disconnected from WebSocket for room ${roomId}`),
    onError: (event) => console.error("WebSocket error:", event),
    shouldReconnect: (closeEvent) => true,
  });

  const [peerConnections, setPeerConnections] = useState({});
  const [streams, setStreams] = useState([]);
  const [sharingStatus, setSharingStatus] = useState({});
  const [isRecording, setIsRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [cameraFacingMode, setCameraFacingMode] = useState("environment"); // Default to back camera
  const mediaRecorderRef = useRef(null);

  const iceServers = {
    iceServers: [
      { urls: "stun:betaz.io:3478" },
      {
        urls: "turn:betaz.io:3478",
        username: ICE_USER,
        credential: ICE_CREDENTIAL
      }
    ]
  };

  const handleIncomingMessage = async (event) => {
    const data = JSON.parse(event.data);
    switch (data.type) {
      case "OFFER":
        await handleOffer(data.offer, data.userId, data.streamType);
        break;
      case "ANSWER":
        await handleAnswer(data.answer, data.userId, data.streamType);
        break;
      case "ICE_CANDIDATE":
        await handleICECandidate(data.candidate, data.userId, data.streamType);
        break;
      case "STOP_SHARING":
        handleStopSharing(data.userId, data.streamInfo.type, false);
        break;
      case "ROOM_JOINED":
        data.streams.forEach((streamInfo) => {
          createPeer(streamInfo.userId, streamInfo.streamId, streamInfo.type);
          sendJsonMessage({ type: "REQUEST_OFFER", userId: streamInfo.userId, targetUserId: authState?.user?._id, streamType: streamInfo.type });
        });
        break;
      case "REQUEST_OFFER":
        handleRequestOffer(data.userId, data.streamType);
        break;
      default:
        break;
    }
  };

  const handleOffer = async (offer, userId, streamType) => {
    const peer = createPeer(userId, streamType);
    if (peer.signalingState !== "stable") {
      await waitForState(peer, "stable");
    }
    await peer.setRemoteDescription(new RTCSessionDescription(offer));
    const answer = await peer.createAnswer();
    await peer.setLocalDescription(answer);
    sendJsonMessage({ type: "ANSWER", answer, roomId, userId, streamType });
  };

  const handleAnswer = async (answer, userId, streamType) => {
    const peer = peerConnections[`${userId}-${streamType}`];
    if (peer) {
      if (peer.signalingState !== "have-local-offer") {
        await waitForState(peer, "have-local-offer");
      }
      await peer.setRemoteDescription(new RTCSessionDescription(answer));
    }
  };

  const handleICECandidate = async (candidate, userId, streamType) => {
    const peer = peerConnections[`${userId}-${streamType}`];
    if (peer) {
      const iceCandidate = new RTCIceCandidate(candidate);
      await peer.addIceCandidate(iceCandidate);
    }
  };

  const handleRequestOffer = async (targetUserId, streamType) => {
    const userId = authState?.user?._id;
    const peer = peerConnections[`${userId}-${streamType}`];
    if (peer) {
      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
      sendJsonMessage({ type: "OFFER", offer, roomId, userId: targetUserId, streamType });
    }
  };

  const createPeer = (userId, streamType) => {
    const peer = new RTCPeerConnection(iceServers);
    peer.onicecandidate = (event) => {
      if (event.candidate) {
        sendJsonMessage({ type: "ICE_CANDIDATE", candidate: event.candidate, roomId, userId, streamType });
      }
    };
    peer.ontrack = (event) => {
      setStreams((prevStreams) => {
        const existingStream = prevStreams.find((stream) => stream.userId === userId && stream.type === streamType);
        if (existingStream) {
          existingStream.stream.getTracks().forEach((track) => track.stop());
          return prevStreams.map((stream) => stream.userId === userId && stream.type === streamType ? { userId, stream: event.streams[0], type: streamType } : stream);
        }
        return [...prevStreams, { userId, stream: event.streams[0], type: streamType }];
      });
    };
    setPeerConnections((prevConnections) => ({ ...prevConnections, [`${userId}-${streamType}`]: peer }));
    return peer;
  };

  const waitForState = (peer, state) => {
    return new Promise((resolve) => {
      const checkState = () => {
        if (peer.signalingState === state) {
          resolve();
        } else {
          setTimeout(checkState, 100);
        }
      };
      checkState();
    });
  };

  const handleShareScreen = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      const userId = authState?.user?._id;
      const existingStream = streams.find((stream) => stream.userId === userId && stream.type === "screen");
      if (existingStream) {
        existingStream.stream.getTracks().forEach((track) => track.stop());
      }
      setStreams((prevStreams) => [...prevStreams.filter((stream) => !(stream.userId === userId && stream.type === "screen")), { userId, stream, type: "screen" }]);
      const peer = createPeer(userId, "screen");
      stream.getTracks().forEach((track) => peer.addTrack(track, stream));
      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
      sendJsonMessage({ type: "OFFER", offer, roomId, userId, streamType: "screen" });
      sendJsonMessage({ type: "STREAM_STARTED", streamInfo: { userId, type: "screen" } });
      setSharingStatus((prevStatus) => ({ ...prevStatus, [userId]: { ...prevStatus[userId], screen: true } }));
    } catch (error) {
      console.error("Error sharing screen:", error);
    }
  };

  const handleShareCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const userId = authState?.user?._id;
      const existingStream = streams.find((stream) => stream.userId === userId && stream.type === "camera");
      if (existingStream) {
        existingStream.stream.getTracks().forEach((track) => track.stop());
      }
      setStreams((prevStreams) => [...prevStreams.filter((stream) => !(stream.userId === userId && stream.type === "camera")), { userId, stream, type: "camera" }]);
      const peer = createPeer(userId, "camera");
      stream.getTracks().forEach((track) => peer.addTrack(track, stream));
      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
      sendJsonMessage({ type: "OFFER", offer, roomId, userId, streamType: "camera" });
      sendJsonMessage({ type: "STREAM_STARTED", streamInfo: { userId, type: "camera" } });
      setSharingStatus((prevStatus) => ({ ...prevStatus, [userId]: { ...prevStatus[userId], camera: true } }));
    } catch (error) {
      console.error("Error sharing camera:", error);
    }
  };

  const handleShareMobileCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: cameraFacingMode } });
      const userId = authState?.user?._id;
      const existingStream = streams.find((stream) => stream.userId === userId && stream.type === "mobile-camera");
      if (existingStream) {
        existingStream.stream.getTracks().forEach((track) => track.stop());
      }
      setStreams((prevStreams) => [...prevStreams.filter((stream) => !(stream.userId === userId && stream.type === "mobile-camera")), { userId, stream, type: "mobile-camera" }]);
      const peer = createPeer(userId, "mobile-camera");
      stream.getTracks().forEach((track) => peer.addTrack(track, stream));
      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
      sendJsonMessage({ type: "OFFER", offer, roomId, userId, streamType: "mobile-camera" });
      sendJsonMessage({ type: "STREAM_STARTED", streamInfo: { userId, type: "mobile-camera" } });
      setSharingStatus((prevStatus) => ({ ...prevStatus, [userId]: { ...prevStatus[userId], "mobile-camera": true } }));
    } catch (error) {
      console.error("Error sharing mobile camera:", error);
    }
  };

  const handleSwitchCamera = async () => {
    const newFacingMode = cameraFacingMode === "environment" ? "user" : "environment";
    setCameraFacingMode(newFacingMode);
    await handleShareMobileCamera();
  };

  const handleStopSharing = (userId, type, notify = true) => {
    const peer = peerConnections[`${userId}-${type}`];
    if (peer) {
      peer.close();
      setPeerConnections((prevConnections) => {
        const { [`${userId}-${type}`]: _, ...rest } = prevConnections;
        return rest;
      });
    }
    setStreams((prevStreams) => {
      const streamToStop = prevStreams.find((stream) => stream.userId === userId && stream.type === type);
      if (streamToStop) {
        streamToStop.stream.getTracks().forEach((track) => track.stop());
      }
      return prevStreams.filter((stream) => !(stream.userId === userId && stream.type === type));
    });
    if (notify) {
      sendJsonMessage({ type: "STOP_SHARING", userId, roomId, streamInfo: { type } });
      sendJsonMessage({ type: "STREAM_STOPPED", userId, streamInfo: { type } });
    }
    setSharingStatus((prevStatus) => ({ ...prevStatus, [userId]: { ...prevStatus[userId], [type]: false } }));
  };

  const handleStartRecording = () => {
    const stream = streams.find(stream => stream.userId === authState?.user?._id && stream.type === "screen")?.stream;
    if (stream) {
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm; codecs=vp9"
      });
      mediaRecorderRef.current.ondataavailable = handleDataAvailable;
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } else {
      console.error("No screen stream available for recording.");
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setRecordedChunks((prev) => [...prev, event.data]);
    }
  };

  const handleSaveRecording = () => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `recording_${roomId}.webm`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  };

  useEffect(() => {
    if (lastMessage !== null) {
      handleIncomingMessage(lastMessage);
    }
  }, [lastMessage]);

  useEffect(() => {
    if (readyState === WebSocket.CLOSED) {
      console.log("WebSocket is closed, attempting to reconnect...");
    }
  }, [readyState]);

  const currentUserId = authState?.user?._id;
  const isArbiter = authState?.user?.roles?.includes("arbitration");
  const streamsData = isArbiter ? streams : streams?.filter((user) => user?.userId === currentUserId);

  return (
    <Box>
      <Text mb={"24px"} textAlign={"center"}><b>Room</b>: {roomId}</Text>
      <Flex p={"12px"} gap={"24px"} direction={"column"}>
        <SimpleGrid columns={streamsData.length} spacing={"12px"}>
          {streamsData.map(({ userId, stream, type }) => (
            <Box key={userId + type} maxW={"100%"}>
              <Text>{type === "screen" ? "Screen Share" : type === "mobile-camera" ? "Mobile Camera" : "Camera"} - User Id: {userId}</Text>
              <video controls autoPlay ref={(ref) => ref && (ref.srcObject = stream)} />
            </Box>
          ))}
        </SimpleGrid>
        <Flex justifyContent={"center"} gap={"12px"}>
          {!isArbiter && sharingStatus[currentUserId]?.screen && (
            <>
              {isRecording ? (
                <Button maxW={"240px"} onClick={handleStopRecording}>
                  Stop Recording
                </Button>
              ) : (
                <Button maxW={"240px"} onClick={handleStartRecording}>
                  Start Recording
                </Button>
              )}
              <Button maxW={"240px"} onClick={handleSaveRecording} disabled={!recordedChunks.length}>
                Save Recording
              </Button>
            </>
          )}
          {!isArbiter && sharingStatus[currentUserId]?.screen ? (
            <Button maxW={"240px"} onClick={() => handleStopSharing(currentUserId, "screen")}>
              Stop Sharing Screen
            </Button>
          ) : (
            <Button maxW={"240px"} onClick={handleShareScreen}>
              Share Screen
            </Button>
          )}
          {!isArbiter && sharingStatus[currentUserId]?.camera ? (
            <Button maxW={"240px"} onClick={() => handleStopSharing(currentUserId, "camera")}>
              Stop Sharing Camera
            </Button>
          ) : (
            <Button maxW={"240px"} onClick={handleShareCamera}>
              Share Camera
            </Button>
          )}
          {!isArbiter && sharingStatus[currentUserId]?.["mobile-camera"] ? (
            <>
              <Button maxW={"240px"} onClick={() => handleStopSharing(currentUserId, "mobile-camera")}>
                Stop Sharing Mobile Camera
              </Button>
              <Button maxW={"240px"} onClick={handleSwitchCamera}>
                Switch Camera
              </Button>
            </>
          ) : (
            <Button maxW={"240px"} onClick={handleShareMobileCamera}>
              Share Mobile Camera
            </Button>
          )}
          {isArbiter && <Button maxW={"240px"}>Ban User</Button>}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Room;
