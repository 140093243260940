import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { Box, Text } from "@chakra-ui/react";

const PrivateRoute = () => {
  const { authState } = useAuth();

  if (authState.isLoading) {
    return (
      <Box textAlign={"center"}>
        <Text>Loading...</Text>
      </Box>
    );
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return <Outlet />;
};

export default PrivateRoute;