// src/App.js
import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState, useCallback } from "react";
import io from "socket.io-client";
import { useAuth } from "../../contexts/AuthContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Chess } from "chess.js";
import Chessboard from "chessboardjsx";

const WSS_URL = process.env.REACT_APP_WSS2_BASE_URL || "http://localhost:3011";
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3001";

const squareStyling = ({ pieceSquare }) => {
  return {
    [pieceSquare]: { backgroundColor: "rgba(255, 255, 0, 0.4)" },
  };
};

function GamePage() {
  const { gameId } = useParams();
  const [socket, setSocket] = useState(null);
  const [game, setGame] = useState(new Chess());
  const [fen, setFen] = useState("start");
  const [turn, setTurn] = useState("w");
  const [squareStyles, setSquareStyles] = useState({});
  const [dropSquareStyle, setDropSquareStyle] = useState({});
  const [pieceSquare, setPieceSquare] = useState("");
  const [promotion, setPromotion] = useState("q");
  const [isPromotion, setIsPromotion] = useState(true);
  const {
    authState: { user },
  } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const newSocket = io(WSS_URL, { auth: { token } });
      setSocket(newSocket);

      newSocket.on("connect", () =>
        console.log("Connected to WebSocket server")
      );
      newSocket.on("connect_error", (error) =>
        console.error("Connection error:", error)
      );
      newSocket.on("newmove", (data) => {
        setFen(data?.fen);
        setTurn(data?.turn);
        setGame(new Chess(data?.fen));
      });
      newSocket.on("notYouTurn", (data) => {
        if (user._id !== data.turn_player)
          toast.error(`Turn player ${data.turn_player}`);
      });

      return () => newSocket.close();
    }
  }, []);

  useEffect(() => {
    if (socket) {
      socket.emit("joinGame", { game_id: gameId });
    }
  }, [socket, gameId]);

  useEffect(() => {
    const loadGame = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/loadGame?game_id=${gameId}`
        );
        const { game } = response.data;
        setFen(game?.fen || "start");
        setTurn(game?.turn_player || "w");
        setGame(new Chess(game?.fen || "start"));
        console.log({ game });
      } catch (error) {
        console.error("Error fetching game data:", error);
      }
    };
    loadGame();
  }, [gameId, socket]);

  const onDrop = useCallback(
    ({ sourceSquare, targetSquare }) => {
      if (socket) {
        if (turn === "w" || turn === "b") {
          const move = {
            from: sourceSquare,
            to: targetSquare,
            turn,
            address: "0x654Af47D0Bbef73d9Da23fACbea6e1c191Cb8dD9",
            userid: user?._id,
            isPromotion: isPromotion,
            fen,
            game_id: gameId,
          };
          socket.emit("move", move);
        } else {
          toast.error("It's not your turn!");
        }
      }
    },
    [socket, turn, user?._id, fen, gameId]
  );

  const onMouseOverSquare = useCallback(
    (square) => {
      let moves = game.moves({
        square: square,
        verbose: true,
      });

      if (moves.some((obj) => obj.hasOwnProperty("promotion"))) {
        setIsPromotion(true);
        setPromotion("q");
      }
      if (moves.length === 0) return;

      let squaresToHighlight = [];
      for (var i = 0; i < moves.length; i++) {
        squaresToHighlight.push(moves[i].to);
      }

      const highlightStyles = [square, ...squaresToHighlight].reduce((a, c) => {
        return {
          ...a,
          [c]: {
            background: "radial-gradient(circle, #fffc00 36%, transparent 40%)",
            borderRadius: "50%",
          },
        };
      }, squareStyling({ pieceSquare }));

      setSquareStyles((prev) => ({ ...prev, ...highlightStyles }));
    },
    [game, pieceSquare]
  );

  const onMouseOutSquare = useCallback(() => {
    setSquareStyles(squareStyling({ pieceSquare }));
  }, [pieceSquare]);

  const onDragOverSquare = useCallback((square) => {
    setDropSquareStyle(
      square === "e4" || square === "d4" || square === "e5" || square === "d5"
        ? { backgroundColor: "cornFlowerBlue" }
        : { boxShadow: "inset 0 0 1px 4px rgb(255, 255, 0)" }
    );
  }, []);

  const onSquareClick = useCallback(
    (square) => {
      setPieceSquare(square);
      setSquareStyles(squareStyling({ pieceSquare: square }));

      let move = game.move({
        from: pieceSquare,
        to: square,
        promotion: "q",
      });

      if (move === null) return;

      setFen(game.fen());
      setPieceSquare("");
    },
    [game, pieceSquare]
  );

  const onSquareRightClick = useCallback((square) => {
    setSquareStyles({ [square]: { backgroundColor: "deepPink" } });
  }, []);
  return (
    <Box w="100%">
      <Text textAlign="center">Chess Game - Turn: {turn}</Text>
      <Box mx="auto" w="max-content">
        <Chessboard
          position={fen}
          onDrop={onDrop}
          onMouseOverSquare={onMouseOverSquare}
          onMouseOutSquare={onMouseOutSquare}
          squareStyles={squareStyles}
          dropSquareStyle={dropSquareStyle}
          onDragOverSquare={onDragOverSquare}
          onSquareClick={onSquareClick}
          onSquareRightClick={onSquareRightClick}
        />
      </Box>
    </Box>
  );
}

export default GamePage;
